import axios from 'axios';
import apiRequest from '../helpers/api';

export const sendFeedback = feedback => {
  return dispatch => {
    const url = `feedback/`;

    const config = apiRequest('POST', url, {
      ...feedback
    });

    return axios(config);
  };
};
