import axios, { CancelToken } from 'axios';
import apiRequest from '../helpers/api';
import {
  setItems,
  apiErrorHandler,
  setLoadingState,
  setAdditionalData,
  updateAdditionalData
} from './generic_items';

export const startSetWakeupCalls = (
  page = 1,
  query = '',
  sortField = 'extension',
  sortOrder = 'asc'
) => {
  return (dispatch, getState) => {
    const pageSize = getState().profile.profileData.page_size;
    const groupId = getState().profile.profileData.group_id;
    const order = sortOrder === 'asc' ? '' : '-';

    const url = `wakeup_call/?page=${page}&page_size=${pageSize}&extension__group=${groupId}&ordering=${order}${sortField}&search=${encodeURIComponent(
      query
    )}`;

    const config = apiRequest('GET', url);

    config.cancelToken = new CancelToken(cancelRequest => {
      cancelAllRequests = cancelRequest;
    });

    dispatch(setLoadingState('WAKEUP_CALL'));

    return axios(config)
      .then(data => {
        const { results, count } = data.data;

        dispatch(setItems(results, query, page, count, sortField, sortOrder));
      })
      .catch(error => dispatch(apiErrorHandler(error)));
  };
};

export const startSetWakeupCallData = () => {
  return (dispatch, getState) => {
    const groupId = getState().profile.profileData.group_id;
    const url = `groups/${groupId}/`;

    const config = apiRequest('GET', url);

    config.cancelToken = new CancelToken(cancelRequest => {
      cancelAllRequests = cancelRequest;
    });

    return axios(config)
      .then(resGroups => {
        const groups = {
          ...resGroups.data
        };

        dispatch(
          setAdditionalData({
            groups
          })
        );

        dispatch(startSetWakeupCalls());
      })
      .catch(error => dispatch(apiErrorHandler(error)));
  };
};

export const startEditDefaultWakeup = updates => {
  return (dispatch, getState) => {
    const additionalData = getState().genericItems.additionalData;
    const groupId = getState().profile.profileData.group_id;
    const url = `groups/${groupId}/`;

    const data = {
      ...updates
    };

    const config = apiRequest('PUT', url, data);

    return axios(config).then(() => {
      additionalData.groups = updates;
      dispatch(updateAdditionalData(additionalData));
    });
  };
};

export const startInsertWakeupCall = wakeupCall => {
  return (dispatch, getState) => {
    const groupId = getState().profile.profileData.group_id;
    const url = `wakeup_call/`;

    // Language id is hardcoded until dropdowns get merged, please remove it afterwards
    const config = apiRequest('POST', url, {
      group_id: groupId,
      ...wakeupCall
    });

    return axios(config).then(() => {
      dispatch(startReloadWakeupCall());
    });
  };
};

export const startEditWakeupCall = (id, updates) => {
  return dispatch => {
    const url = `wakeup_call/${id}/`;

    // Language id is hardcoded until dropdowns get merged, please remove it afterwards
    const data = {
      ...updates
    };

    const config = apiRequest('PUT', url, data);

    return axios(config).then(() => {
      dispatch(startReloadWakeupCall());
    });
  };
};

export const startDeleteWakeupCall = (id, nextPage) => {
  return (dispatch, getState) => {
    const url = `wakeup_call/${id}/`;
    const config = apiRequest('DELETE', url);

    return axios(config).then(() => {
      const { query, sortField, sortOrder } = getState().genericItems;
      dispatch(startSetWakeupCalls(nextPage, query, sortField, sortOrder));
    });
  };
};

export const startReloadWakeupCall = () => {
  return (dispatch, getState) => {
    const {
      currentPage,
      query,
      sortField,
      sortOrder
    } = getState().genericItems;

    dispatch(startSetWakeupCalls(currentPage, query, sortField, sortOrder));
  };
};

export let cancelAllRequests = (message = '') => null;
