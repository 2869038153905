// SET_LANGUAGE
export const setLanguage = (lang = 'en') => ({
  type: 'SET_LANGUAGE',
  lang
});

export const setDashboardConfig = (dashboard = {}) => ({
  type: 'SET_DASHBOARD_CONFIG',
  dashboard
});
