import axios, { CancelToken } from 'axios';
import apiRequest from '../helpers/api';
import { startLogout } from './auth';
import { startEditDefaultWakeup } from './wakeup_call';
import { initFaqBOT } from '../FaqBOT';

// SET_PROFILE_INIT_DATA
export const setProfileInitialData = (group_id, account_id) => ({
  type: 'SET_PROFILE_INIT_DATA',
  group_id,
  account_id
});

// SET_PROFILE
export const setProfile = data => ({
  type: 'SET_PROFILE',
  data
});

// IMPERSONATE_PROFILE
export const impersonateProfile = (groupId, accountId, groupData) => ({
  type: 'IMPERSONATE_PROFILE',
  groupId,
  accountId,
  groupData
});

// IMPERSONATE_PROFILE
export const disableImpersonate = () => ({
  type: 'IMPERSONATE_PROFILE_DISABLE'
});

export const startSetImpersonateData = (groupId = null, accountId = null) => {
  return dispatch => {
    const url = `groups/${groupId}/`;
    let groupData = { id: `${groupId}`, label: 'Loading...' };

    const config = apiRequest('GET', url);

    // Set initial impersonate data
    dispatch(impersonateProfile(groupId, accountId, groupData));

    return axios(config).then(data => {
      const response = data.data;

      // Create new instance of groupData object
      groupData = { id: `${response.id}`, label: response.description };

      // Set impersonate data with group description
      dispatch(impersonateProfile(groupId, accountId, groupData));
    });
  };
};

export const startSetProfile = (authToken = '') => {
  return (dispatch, getState) => {
    const url = 'profile/';

    const config = apiRequest('GET', url);

    if (!config.headers['Authorization']) {
      config.headers['Authorization'] = `Token ${authToken}`;
    }

    return axios(config)
      .then(data => {
        const response = data.data;
        const {
          impersonate,
          impersonateGroup,
          impersonateAccount,
          profileData
        } = getState().profile;

        // set initial group_id and account_id
        dispatch(setProfileInitialData(response.group_id, response.account_id));

        // Check if user enabled impersonate mode before reload
        if (impersonate) {
          response.group_id = impersonateGroup;
          response.account_id = impersonateAccount;
          response.group_data = profileData.group_data;
        }

        initFaqBOT(response.username);

        dispatch(setProfile(response));
      })
      .catch(() => {
        dispatch(startLogout());
      });
  };
};

export const startChangePassword = data => {
  return () => {
    const url = 'change_password/';

    const changePassword = {
      old_password: data.oldPassword,
      new_password: data.newPassword
    };

    const config = apiRequest('PUT', url, changePassword);

    return axios(config);
  };
};

// SET_USER_SETTINGS
export const setUserSettings = userSettings => ({
  type: 'SET_USER_SETTINGS',
  userSettings
});

export const startSetUserSettings = () => {
  return (dispatch, getState) => {
    const groupId = getState().profile.profileData.group_id;
    const url = `groups/${groupId}/`;

    const config = apiRequest('GET', url);

    config.cancelToken = new CancelToken(cancelRequest => {
      cancelAllRequests = cancelRequest;
    });

    return axios(config).then(data => {
      const results = data.data;

      dispatch(setUserSettings(results));
    });
  };
};

export const startEditUserSettings = updates => {
  return (dispatch, getState) => {
    const groupId = getState().profile.profileData.group_id;
    const url = `groups/${groupId}/`;

    const config = apiRequest('PUT', url, {
      ...updates
    });

    return axios(config).then(() => {
      dispatch(
        startEditDefaultWakeup({
          ...updates
        })
      );
    });
  };
};

// PROFILE_UNSET_INITIAL_FLAG
export const profileUnsetInitialFlag = () => ({
  type: 'PROFILE_UNSET_INITIAL_FLAG'
});

// RESET_PROFILE
export const resetProfile = () => ({
  type: 'RESET_PROFILE'
});

export let cancelAllRequests = (message = '') => null;
