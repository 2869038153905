const profileReducerDefaultState = {
  profileData: {},
  userSettings: {},
  impersonate: false,
  initialGroup: null,
  initialAccount: null,
  impersonateGroup: null,
  impersonateAccount: null
};

export default (state = profileReducerDefaultState, action) => {
  let profileData = {};

  switch (action.type) {
    case 'SET_PROFILE_INIT_DATA':
      return {
        ...state,
        initialGroup: action.group_id,
        initialAccount: action.account_id
      };
    case 'SET_PROFILE':
      return {
        ...state,
        profileData: action.data
      };
    case 'SET_USER_SETTINGS':
      return {
        ...state,
        userSettings: action.userSettings
      };
    case 'IMPERSONATE_PROFILE':
      profileData = state.profileData;

      profileData.group_id = action.groupId;
      profileData.account_id = action.accountId;
      profileData.group_data = action.groupData;

      return {
        ...state,
        profileData: profileData,
        impersonate: true,
        impersonateGroup: action.groupId,
        impersonateAccount: action.accountId
      };
    case 'IMPERSONATE_PROFILE_DISABLE':
      return {
        ...state,
        impersonate: false,
        impersonateGroup: null,
        impersonateAccount: null
      };
    case 'PROFILE_UNSET_INITIAL_FLAG':
      profileData = state.profileData;

      profileData.initial_password = false;

      return {
        ...state,
        profileData
      };
    case 'RESET_PROFILE':
      return profileReducerDefaultState;
    default:
      return state;
  }
};
