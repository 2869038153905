import {
  isEmpty,
  convertObjectToArray,
  getItemID
} from '../../helpers/serializer_helper';

export const deserializeData = profile => {
  const { account_id, group_data } = profile;
  const group = convertObjectToArray(group_data);
  const accountId = account_id || null;

  return { group, accountId };
};

export const serializeProfile = profile => {
  const { group, accountId } = profile;
  const groupId = getItemID(group, false);
  const groupData = !isEmpty(group) ? group[0] : {};

  return { groupId, accountId, groupData };
};
