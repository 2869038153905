import React from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';

// views
import { DefaultLayout } from '../containers';
import Login from '../views/Pages/Login/';
import { Page404 } from '../views/Pages/Page404';

export const history = createBrowserHistory();

const AppRouter = () => (
  <Router history={history}>
    <div>
      <Switch>
        <PublicRoute path="/" component={Login} exact={true} />
        <PrivateRoute path="/" component={DefaultLayout} />
        <Route component={Page404} />
      </Switch>
    </div>
  </Router>
);

export default AppRouter;
