import permissions from '../permissions';

/** Get authorization level based on impersonate/reseller flags
 * @param {boolean} impersonate - impersonate flag
 * @param {boolean} reseller - reseller flag
 * @returns {string} authorization level (ADMIN, RESELLER, USER)
 */
export const getAuthorizationLevel = (
  impersonate = false,
  reseller = false
) => {
  // Check if user is admin
  if (impersonate && !reseller) return 'ADMIN';

  // Check if user is reseller
  if (reseller) return 'RESELLER';

  // Set the lowest permission level
  return 'USER';
};

/** Check if item is authorized
 * @param {object} item - item object
 * @param {string} authorizationLevel - item authorization level
 * @returns {boolean} returns true/false depending on authorizationLevel
 */
export const isAuthorized = (item, authorizationLevel) => {
  const authKey = item.authKey;
  const itemPermission = permissions[authKey];

  // Check if permissions are set for this item
  if (!itemPermission) return true;

  // Check permissions based on authorization level
  return itemPermission[authorizationLevel];
};
