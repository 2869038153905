const FAQBOT_URL = 'https://faqbot.co/public/js/bundle.js';
const FAQBOT_PUBLIC_KEY = '70b9228a8561b60a6ec8da0fb970fef0773b9f85';
const FAQBOT_CONTAINER_ID = 'faqbot-58rZl8DgthS';
const FAQBOT_INSTANCE_ID = 'faqbot-script-58rZl8DgthS';
const FAQBOT_CHAT_CLASS = 'faqbot-message-container-wrap';

const initFaqBOT = (userName = '') => {
  const faqBOTExists = document.getElementById(FAQBOT_INSTANCE_ID);

  // Early exit if FAQ BOT already in the DOM
  if (faqBOTExists) return;

  const faqBOT = {
    PUBLIC_KEY: FAQBOT_PUBLIC_KEY,
    CUSTOM_DATA: { name: userName }
  };

  window.FAQBOT = faqBOT;

  // Create BOT Container element
  const faqBOTContainer = document.createElement('div');

  faqBOTContainer.id = FAQBOT_CONTAINER_ID;

  // Load BOT script
  const faqBOTScript = document.createElement('script');

  faqBOTScript.id = FAQBOT_INSTANCE_ID;
  faqBOTScript.src = FAQBOT_URL;
  faqBOTScript.async = true;

  document.body.appendChild(faqBOTContainer);
  document.body.appendChild(faqBOTScript);
};

const destroyFaqBOT = () => {
  const faqBOTInstance = document.getElementById(FAQBOT_INSTANCE_ID);

  // Early exit if FAQ BOT not in DOM
  if (!faqBOTInstance) return;

  const faqBOTContainer = document.getElementById(FAQBOT_CONTAINER_ID);

  // Remove stupid chat window if it was activated before destroy
  const bodyElements = document.body.children;
  const lastElement = bodyElements[bodyElements.length - 1];

  // It should be the last div element in DOM
  if (
    lastElement.tagName === 'DIV' &&
    !lastElement.id &&
    !lastElement.className
  ) {
    const hasClass = lastElement.getElementsByClassName(FAQBOT_CHAT_CLASS)[0];

    // If class exists, chant window is active. Remove it from the DOM.
    if (hasClass) document.body.removeChild(lastElement);
  }

  // Remove the rest of BOT crap
  window.FAQBOT = null;
  document.body.removeChild(faqBOTInstance);
  document.body.removeChild(faqBOTContainer);
};

export { initFaqBOT, destroyFaqBOT };
