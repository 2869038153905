// Sites Reducer

const cdrViewerReducerDefaultState = {
  cdrs: [],
  count: 0,
  currentPage: 1,
  isLoading: true,
  noErrors: true,
  message: ''
};

export default (state = cdrViewerReducerDefaultState, action) => {
  switch (action.type) {
    case 'SET_CDRS':
      return {
        ...state,
        cdrs: action.cdrs,
        count: action.count,
        currentPage: action.currentPage,
        isLoading: false
      };
    case 'SET_CDRS_ERROR':
      return {
        ...state,
        isLoading: false,
        noErrors: false,
        message: action.message
      };
    case 'RESET_CDRS':
      return {
        ...cdrViewerReducerDefaultState
      };
    default:
      return state;
  }
};
