// SET_TOUR_STEPS
export const setTourSteps = (steps = []) => ({
  type: 'SET_TOUR_STEPS',
  steps
});

export const setTourCurrentStep = step => ({
  type: 'SET_TOUR_CURRENT_STEP',
  step
});

export const openTour = () => ({
  type: 'OPEN_TOUR'
});

export const closeTour = () => ({
  type: 'CLOSE_TOUR'
});
