import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { applyBodyClasses } from './shared/classes';

const propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  fixed: PropTypes.bool
};

const defaultProps = {
  className: '',
  fixed: false
};

const AppFooter = props => {
  const { className, children } = props;

  useEffect(() => {
    applyBodyClasses(props, { fixed: 'footer-fixed' });
  }, [props]);

  return <footer className={`app-footer ${className}`}>{children}</footer>;
};

AppFooter.propTypes = propTypes;
AppFooter.defaultProps = defaultProps;

export default AppFooter;
