import React from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardBody,
  FormGroup,
  InputGroup,
  Input,
  Button,
  Collapse
} from 'reactstrap';
import continents from './continent_countries.json';
import Checkbox from '../Checkbox/Checkbox';

const propTypes = {
  selected: PropTypes.array,
  onChange: PropTypes.func.isRequired
};

const defaultProps = {
  selected: []
};

class ContinentCountrySelect extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeItems: {
        AF: false,
        AN: false,
        AS: false,
        OC: false,
        EU: false,
        NA: false,
        SA: false
      },
      query: ''
    };

    this.onContinentChange = this.onContinentChange.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.onClear = this.onClear.bind(this);
    this.onSelect = this.onSelect.bind(this);
    this.onSelectAll = this.onSelectAll.bind(this);
  }

  onContinentChange(item) {
    const { activeItems } = this.state;

    activeItems[item] = !activeItems[item];

    this.setState({ activeItems });
  }

  onSearch(event) {
    const { activeItems } = this.state;
    const keys = Object.keys(activeItems);

    const target = event.target;
    const name = target.name;
    const value = target.value;
    const openMenu = value.trim().length >= 3;

    keys.forEach(key => {
      activeItems[key] = openMenu;
    });

    this.setState({ activeItems, [name]: value });
  }

  onClear() {
    const { activeItems } = this.state;
    const keys = Object.keys(activeItems);

    keys.forEach(key => {
      activeItems[key] = false;
    });

    this.setState({ query: '', activeItems });
  }

  onSelect(event) {
    const { selected, onChange } = this.props;

    const target = event.target;
    const name = target.name;
    const value = target.type === 'checkbox' ? target.checked : target.value;

    if (value) {
      if (!selected.includes(name)) {
        selected.push(name);
      }
    } else {
      if (selected.includes(name)) {
        const index = selected.indexOf(name);
        selected.splice(index, 1);
      }
    }

    onChange(selected);
  }

  onSelectAll(item, selectedState) {
    const { selected, onChange } = this.props;
    const continent = continents.find(c => c.code === item);

    continent.countries.forEach(c => {
      if (selectedState) {
        if (!selected.includes(c.code)) {
          selected.push(c.code);
        }
      } else {
        if (selected.includes(c.code)) {
          const index = selected.indexOf(c.code);
          selected.splice(index, 1);
        }
      }
    });

    onChange(selected);
  }

  render() {
    const { selected } = this.props;
    const { activeItems, query } = this.state;

    // Continents component
    const Continents = () => {
      const userQuery = query.trim().toLowerCase();
      const continentsCount = continents.length - 1;
      const shouldFilter = userQuery.length >= 3 && query.length <= 20;
      let allCountries = 0;

      return continents.map((continent, index) => {
        const filtered = shouldFilter
          ? continent.countries.filter(c => {
              const countryName = c.name.toLowerCase();

              return countryName.indexOf(userQuery) !== -1;
            })
          : continent.countries;
        const filterSelected = filtered.filter(c => selected.includes(c.code));
        const selectedCountries = filterSelected.length;
        const totalCount = filtered.length;
        const selectedState = selectedCountries !== totalCount;

        allCountries = allCountries + totalCount;

        // Return no countries message
        if (index === continentsCount && !allCountries) {
          return (
            <Card className="mb-2">
              <CardBody className="collapse-header">
                No countries found.
              </CardBody>
            </Card>
          );
        }

        // Hide continent if there are no countries
        if (!totalCount) return null;

        return (
          <Card key={continent.code} className="mb-2">
            <CardBody className="collapse-header">
              <div className="title">
                <Checkbox
                  name={continent.code}
                  id={continent.code}
                  checked={!selectedState}
                  onChange={() =>
                    this.onSelectAll(continent.code, selectedState)
                  }
                  label={`${continent.name} (${selectedCountries}/${totalCount})`}
                />
              </div>
              <div className="actions">
                <Button
                  color="orange"
                  onClick={() => this.onContinentChange(continent.code)}
                >
                  <i
                    className={
                      activeItems[continent.code]
                        ? 'far fa-angle-double-up'
                        : 'far fa-angle-double-down'
                    }
                  />{' '}
                  Toggle
                </Button>
              </div>
            </CardBody>
            <Collapse isOpen={activeItems[continent.code]}>
              <CardBody>
                <div className="countries-container">
                  {filtered.map(country => {
                    const isChecked = selected.includes(country.code);
                    const name = country.code;
                    const label = `${country.name} (+${country.dial_code})`;
                    const flag = country.code.toLowerCase();

                    return (
                      <FormGroup key={country.code} check className="checkbox">
                        <Checkbox
                          id={`${continent.code}_${country.code}_checkbox`}
                          name={name}
                          onChange={this.onSelect}
                          checked={isChecked}
                          value={isChecked}
                          label={
                            <>
                              <i
                                style={{ width: '30px' }}
                                className={`flag-icon flag-icon-${flag} mr-1`}
                              />
                              {label}
                            </>
                          }
                        />
                      </FormGroup>
                    );
                  })}
                </div>
              </CardBody>
            </Collapse>
          </Card>
        );
      });
    };

    return (
      <div>
        <FormGroup>
          <InputGroup>
            <Input
              className="form-control"
              name="query"
              value={query}
              placeholder="Search..."
              onChange={this.onSearch}
            />
            {query && (
              <button
                type="button"
                className="btn"
                style={{ marginLeft: '-40px', zIndex: '100' }}
                onClick={this.onClear}
              >
                &times;
              </button>
            )}
          </InputGroup>
        </FormGroup>
        <Continents />
      </div>
    );
  }
}

ContinentCountrySelect.propTypes = propTypes;
ContinentCountrySelect.defaultProps = defaultProps;
export default ContinentCountrySelect;
