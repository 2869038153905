import React from 'react';
import PropTypes from 'prop-types';
import { Popover, PopoverHeader } from 'reactstrap';
/*
 * Pagination Tutorial
 * https://github.com/ericgio/react-bootstrap-typeahead/blob/2.0/example/examples/AsyncPaginationExample.js
 */

import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import {
  apiErrorHandler,
  cancelAllRequests
} from '../../actions/async_select_actions';

const propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.array.isRequired,
  minLength: PropTypes.number,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  multiple: PropTypes.bool,
  dropup: PropTypes.bool,
  isInvalid: PropTypes.bool,
  itemsPerPage: PropTypes.number,
  filterResults: PropTypes.object,
  placeholder: PropTypes.string,
  paginate: PropTypes.bool,
  onSelect: PropTypes.func.isRequired,
  getData: PropTypes.func.isRequired
};

const defaultProps = {
  minLength: 0,
  multiple: false,
  dropup: false,
  isInvalid: false,
  readOnly: false,
  disabled: false,
  itemsPerPage: 10,
  filterResults: {},
  placeholder: 'Search...',
  paginate: true
};

class AsyncSelect extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      options: [],
      query: '',
      perPage: null,
      notSelected: false
    };

    this._isMounted = true;
    this._idSufix = new Date().getTime();
    this._cache = {};

    this.responseHandler = this.responseHandler.bind(this);
    this.onMenuToggle = this.onMenuToggle.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handlePagination = this.handlePagination.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.onBlur = this.onBlur.bind(this);
  }

  componentDidMount() {
    const setInitialPageSize = () => {
      // Prevent setting state if component is already unmounted
      if (!this._isMounted) return;
      const { itemsPerPage, value } = this.props;
      const perPage = itemsPerPage + value.length;

      this.setState({ perPage });
    };

    // Await props from parent component - dirty hack
    setTimeout(setInitialPageSize, 0);
  }

  componentWillUnmount() {
    this._isMounted = false;
    cancelAllRequests();
  }

  responseHandler(isLoading = false, options = []) {
    this.setState({ isLoading, options });
  }

  onMenuToggle(isOpen) {
    if (isOpen) this.handleSearch('');
  }

  handleSearch(query) {
    const { perPage } = this.state;
    const { filterResults } = this.props;
    const filterLength = Object.keys(filterResults).length;

    if (!filterLength && this._cache[query]) {
      this._cache[query]['page'] = 1;
      this.setState({ options: this._cache[query].options });
      return;
    }

    this.props
      .getData(1, perPage + filterLength, query)
      .then(({ options, total_count }) => {
        this._cache[query] = { options, page: 1, total_count };

        this.responseHandler(false, options);
      })
      .catch(error => apiErrorHandler(error));
  }

  handlePagination(e) {
    const { filterResults } = this.props;
    const { query, perPage } = this.state;
    const cachedQuery = this._cache[query];
    const page = cachedQuery.page + 1;
    const filterLength = Object.keys(filterResults).length;

    // Don't make another request if we already have all the results.
    if (cachedQuery.options.length === cachedQuery.total_count) {
      return;
    }

    this.setState({ isLoading: true });

    this.props
      .getData(page, perPage + filterLength, query)
      .then(res => {
        const options = cachedQuery.options.concat(res.options);

        this._cache[query] = { ...cachedQuery, options, page };

        this.responseHandler(false, options);
      })
      .catch(error => apiErrorHandler(error));
  }

  handleInputChange(query) {
    this.setState({ query });

    if (!query) this.handleSearch('');
  }

  onChange(selected) {
    let itemsPerPage = this.props.itemsPerPage;

    if (selected.length) {
      itemsPerPage = itemsPerPage + selected.length;
    }

    delete this._cache[''];

    this.setState({
      query: '',
      perPage: itemsPerPage,
      notSelected: false
    });

    this.props.onSelect({
      target: {
        name: this.props.name,
        value: selected
      }
    });
  }

  onFocus() {
    this.setState({ notSelected: false });
  }

  onBlur(event) {
    const { value, multiple } = this.props;
    const inputValue = event.target.value;

    if (!multiple) {
      const notSelected = inputValue && !value.length ? true : false;

      this.setState({ notSelected });
    } else {
      const notSelected = inputValue ? true : false;

      this.setState({ notSelected });
    }
  }

  render() {
    const {
      value,
      minLength,
      multiple,
      dropup,
      isInvalid,
      readOnly,
      disabled,
      itemsPerPage,
      placeholder,
      filterResults,
      paginate
    } = this.props;
    const filterLength = Object.keys(filterResults).length;
    let { options, notSelected } = this.state;
    const isInvalidField = isInvalid || notSelected;

    // filter options by filterResults object
    if (filterLength) {
      options = options.filter(option => !filterResults[option.id]);
    }

    return (
      <>
        <AsyncTypeahead
          id={`async_select_${this._idSufix}`}
          delay={300}
          readOnly={readOnly}
          multiple={multiple}
          disabled={disabled}
          dropup={dropup}
          isInvalid={isInvalidField}
          clearButton={true}
          isLoading={this.state.isLoading}
          valueKey="id"
          labelKey="label"
          selected={value}
          minLength={minLength}
          maxResults={itemsPerPage - 1}
          useCache={false}
          paginate={paginate}
          options={options}
          positionFixed={true}
          placeholder={placeholder}
          onMenuToggle={this.onMenuToggle}
          onInputChange={this.handleInputChange}
          onPaginate={this.handlePagination}
          onChange={this.onChange}
          onSearch={this.handleSearch}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
        />
        <div
          id={`async_popover_${this._idSufix}`}
          className="select-popover-style"
        />
        <Popover
          placement="bottom"
          target={`async_popover_${this._idSufix}`}
          isOpen={isInvalidField}
          trigger="legacy"
          delay={0}
        >
          <PopoverHeader>Please select a value.</PopoverHeader>
        </Popover>
      </>
    );
  }
}

AsyncSelect.propTypes = propTypes;
AsyncSelect.defaultProps = defaultProps;
export default AsyncSelect;
