import React, { Component } from 'react';
import {
  Collapse,
  Col,
  FormGroup,
  Label,
  InputGroup,
  Input,
  Button
} from 'reactstrap';
import PropTypes from 'prop-types';

const propTypes = {
  children: PropTypes.node
};

const defaultProps = {};

class DefaultFooter extends Component {
  constructor(props) {
    super(props);

    this.state = { description: '', isOpen: false, message: '' };

    this.onChange = this.onChange.bind(this);
    this.toggle = this.toggle.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({ [name]: value });
  }

  toggle() {
    const { isOpen } = this.state;

    this.setState({ isOpen: !isOpen });
  }

  onSubmit(e) {
    e.preventDefault();

    const { description } = this.state;
    const { username, sendFeedback, translate } = this.props;

    const feedback = {
      requester: username,
      subject: 'MyTelSmart Feedback',
      description: description
    };

    sendFeedback(feedback)
      .then(() => {
        this.setState({ message: translate('feedback_submit_success') });
      })
      .catch(() => {
        this.setState({ message: translate('feedback_submit_error') });
      });
  }

  render() {
    const { description, message, isOpen } = this.state;
    const { translate } = this.props;
    //const iconClass = isOpen ? 'fal fa-times' : 'fal fa-angle-up';

    const year = new Date().getFullYear();
    const version = process.env.REACT_APP_VERSION;

    return (
      <React.Fragment>
        {/*
        Feedback form expand/collapse icon

        <span className="footer-toggle-icon" onClick={this.toggle}>
          <i className={iconClass} />
        </span>
        
        */}
        <div className="footer-body">
          <a href="https://telsmart.eu">&copy; {year} TelSmart</a>
          <span className="ml-2">v{version}</span>
          <b className="ml-auto">{translate('feedback')}</b>
        </div>
        <Collapse isOpen={isOpen}>
          <div className="footer-collapse">
            <Col xl="8">
              {message ? (
                <Label>{message}</Label>
              ) : (
                <div>
                  <FormGroup>
                    <Label>{translate('feedback_submit_text')}</Label>
                    <InputGroup>
                      <Input
                        type="textarea"
                        name="description"
                        value={this.state.description}
                        rows="4"
                        onChange={this.onChange}
                        placeholder={translate('feedback_submit_enter')}
                      />
                    </InputGroup>
                  </FormGroup>
                  <Button
                    className="text-uppercase"
                    color="orange"
                    onClick={this.onSubmit}
                    disabled={description ? false : true}
                  >
                    {translate('feedback_submit')}
                  </Button>
                </div>
              )}
            </Col>
          </div>
        </Collapse>
      </React.Fragment>
    );
  }
}

DefaultFooter.propTypes = propTypes;
DefaultFooter.defaultProps = defaultProps;

export default DefaultFooter;
