// Clients Reducer

const clientsReducerDefaultState = {
  dashboard: {},
  mainChart: {
    labels: [],
    datasets: [],
    avg_inbound: 0,
    avg_outbound: 0,
    avg_inbound_duration: 0,
    avg_outbound_duration: 0
  },
  mainChartOptions: {
    maintainAspectRatio: false,
    legend: {
      display: false
    },
    scales: {
      xAxes: [
        {
          gridLines: {
            drawOnChartArea: false
          }
        }
      ],
      yAxes: [
        {
          ticks: {
            beginAtZero: true
          }
        }
      ]
    },
    elements: {
      point: {
        radius: 0,
        hitRadius: 10,
        hoverRadius: 4,
        hoverBorderWidth: 3
      }
    }
  },
  isLoading: true,
  noErrors: true,
  message: ''
};

export default (state = clientsReducerDefaultState, action) => {
  switch (action.type) {
    case 'SET_CLIENT_DASHBOARD':
      return {
        ...state,
        ...action.dashboard,
        isLoading: false
      };
    case 'SET_CLIENT_DASHBOARD_CHART':
      return {
        ...state,
        mainChart: action.mainChart,
        isLoading: false
      };
    case 'SET_CLIENT_DASHBOARD_ERROR':
      return {
        ...state,
        isLoading: false,
        noErrors: false,
        message: ''
      };
    case 'RESET_CLIENT_DASHBOARD':
      return {
        ...state,
        isLoading: true,
        noErrors: true,
        message: ''
      };
    default:
      return state;
  }
};
