import React from 'react';
import { AppSidebarNav } from '../../components/coreui';

const DefaultNav = props => {
  const { navConfig, translate, dispatch, ...rest } = props;

  // Translate menu items
  navConfig.items.forEach(item => {
    item.name = translate(item.transKey);
  });

  return <AppSidebarNav navConfig={navConfig} {...rest} />;
};

export default DefaultNav;
