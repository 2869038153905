// Events Reducer

const eventsReducerDefaultState = {
  getDashboard: {
    event: 'LOADING',
    message: ''
  },
  getHelpSupport: {
    event: 'LOADING',
    message: ''
  },
  getCustomers: {
    event: 'LOADING',
    message: ''
  },
  getSubscribers: {
    event: 'LOADING',
    message: ''
  },
  getPhonebooks: {
    event: 'LOADING',
    message: ''
  },
  getDevices: {
    event: 'LOADING',
    message: ''
  },
  getDeviceDss: {
    event: 'LOADING',
    message: ''
  }
};

export default (state = eventsReducerDefaultState, action) => {
  switch (action.type) {
    case 'GET_DASHBOARD':
      return {
        ...state,
        getDashboard: action.eventData
      };
    case 'GET_HELPSUPPORT':
      return {
        ...state,
        getHelpSupport: action.eventData
      };
    case 'GET_CUSTOMERS':
      return {
        ...state,
        getCustomers: action.eventData
      };
    case 'GET_SUBSCRIBERS':
      return {
        ...state,
        getSubscribers: action.eventData
      };
    case 'SET_SUBSCRIBER':
      return {
        ...state,
        setSubscriber: action.eventData
      };
    case 'GET_PHONEBOOKS':
      return {
        ...state,
        getPhonebooks: action.eventData
      };
    case 'GET_DEVICES':
      return {
        ...state,
        getDevices: action.eventData
      };
    case 'GET_DEVICE_DSS':
      return {
        ...state,
        getDeviceDss: action.eventData
      };
    default:
      return state;
  }
};
