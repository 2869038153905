// GroupOverview Reducer

const groupOverviewReducerDefaultState = {
  groupOverview: {},
  isLoading: true,
  noErrors: true,
  message: ''
};

export default (state = groupOverviewReducerDefaultState, action) => {
  switch (action.type) {
    case 'SET_GROUP_OVERVIEW':
      return {
        ...state,
        ...action.groupOverview,
        isLoading: false
      };
    case 'SET_GROUP_OVERVIEW_ERROR':
      return {
        ...state,
        isLoading: false,
        noErrors: false,
        message: action.message
      };
    case 'RESET_GROUP_OVERVIEW':
      return {
        ...groupOverviewReducerDefaultState,
        isLoading: true,
        noErrors: true,
        message: ''
      };
    default:
      return state;
  }
};
