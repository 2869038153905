import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { asideMenuCssClasses, applyBodyClasses } from './shared/classes';

const propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  display: PropTypes.string,
  fixed: PropTypes.bool,
  offCanvas: PropTypes.bool,
  hidden: PropTypes.bool
};

const defaultProps = {
  className: '',
  display: '',
  fixed: false,
  offCanvas: true
};

const AppAside = props => {
  const { className, children, display, hidden } = props;

  useEffect(() => {
    applyBodyClasses(props, {
      fixed: 'aside-menu-fixed',
      offCanvas: 'aside-menu-off-canvas'
    });
  }, [props]);

  const classNamesBind = classNames.bind(asideMenuCssClasses);
  const classes = ['aside-menu', display, { 'd-none': hidden }, className];

  return <aside className={classNamesBind(classes)}>{children}</aside>;
};

AppAside.propTypes = propTypes;
AppAside.defaultProps = defaultProps;

export default AppAside;
