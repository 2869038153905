import React from 'react';
import ReactGA from 'react-ga';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';
import configureStore from './store/configureStore';
import { ToastContainer } from 'react-toastify';
import AppTour from './components/AppTour';
import Loader from './components/Loader/Loader';

// Main Style
import './App.scss';
// react-bootstrap-typeahead css style
import 'react-bootstrap-typeahead/css/Typeahead.css';

import AppRouter, { history } from './routers/AppRouter';
import { startLoginUsingToken, startLogout } from './actions/auth';
import { startSetProfile } from './actions/profile';
import { AppProvider } from './AppContext';

// Set redux store
const { persistor, store } = configureStore();
// This will be used by other components
const appData = {
  apiBaseUrl: '',
  initialPath: '',
  helpTextUrl: ''
};

// Set API Base URL
const reactAppEnv = process.env.REACT_APP_ENV;
appData.apiBaseUrl = process.env[`REACT_APP_${reactAppEnv}_API_URL`] || '';

if (reactAppEnv !== 'TEST') {
  ReactGA.initialize('UA-31371744-28', { debug: false });

  history.listen(location => {
    ReactGA.set({ page: location.pathname }); // Update the user's current page
    ReactGA.pageview(location.pathname); // Record a pageview for the given page
  });
}

if (!appData.apiBaseUrl) console.error('API base URL not set.');

// Get authentication token from redux store
const getAuthToken = () => {
  return store.getState().auth.uid || '';
};

// Get active language from redux store
const getLocale = () => {
  return store.getState().locale;
};

// Get translations redux store
const getActiveLanguage = () => {
  return store.getState().settings.lang || 'en';
};

const setInitialPath = (path = '') => {
  appData.initialPath = path;
};

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      displayApp: false
    };

    this.renderLoginPage = this.renderLoginPage.bind(this);
    this.renderDashboardPage = this.renderDashboardPage.bind(this);
    this.onBeforeLift = this.onBeforeLift.bind(this);
  }

  renderLoginPage() {
    // Store initial user path
    const userPath = history.location.pathname;
    setInitialPath(userPath);

    // Destroy all previous user data
    store.dispatch(startLogout());

    this.setState({ displayApp: true });

    history.push('/');
  }

  renderDashboardPage() {
    this.setState(() => ({ displayApp: true }));

    if (history.location.pathname === '/') {
      history.push('/dashboard');
    }

    // const userName = store.getState().profile.profileData.username || '';
  }

  onBeforeLift() {
    const userPath = history.location.pathname;
    const tokenLength = 40; // Default auth token length
    const authToken = getAuthToken();

    if (userPath.indexOf('impersonate') !== -1) {
      const parsedToken = userPath.split('/').pop();

      if (parsedToken && parsedToken.length === tokenLength) {
        store
          .dispatch(startLoginUsingToken(parsedToken))
          .then(() => {
            // Store initial user path
            const userPath = history.location.pathname;
            setInitialPath(userPath);

            this.setState({ displayApp: true });

            this.renderDashboardPage();
          })
          .catch(() => this.renderLoginPage());
      } else {
        this.renderLoginPage();
      }

      return;
    }

    if (authToken) {
      store
        .dispatch(startSetProfile(authToken))
        .then(() => this.renderDashboardPage())
        .catch(() => this.renderLoginPage());
    } else {
      this.renderLoginPage();
    }
  }

  render() {
    const { displayApp } = this.state;
    return (
      <div>
        <Provider store={store}>
          <PersistGate
            loading={<Loader loaderOffset={0} />}
            onBeforeLift={this.onBeforeLift}
            persistor={persistor}
          >
            {displayApp ? (
              <AppProvider getActiveLanguage={getActiveLanguage}>
                <div>
                  <AppRouter />
                  <AppTour />
                  <ToastContainer position="top-right" autoClose={10000} />
                </div>
              </AppProvider>
            ) : (
              <Loader loaderOffset={0} />
            )}
          </PersistGate>
        </Provider>
      </div>
    );
  }
}

export { App, getAuthToken, getLocale, setInitialPath, appData };
