import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';
import { startSetRenderingRob } from '../../../actions/rendering_rob';
import { AppContext } from '../../../AppContext';

class Page404Admin extends Component {
  static contextType = AppContext;

  componentDidMount() {
    this.props.dispatch(startSetRenderingRob());
  }

  render() {
    const robThoughts = this.props.robThoughts;
    const { translate } = this.context;

    return (
      <Row className="justify-content-center page404-admin">
        <Col md="6">
          <div className="clearfix">
            <h1 className="float-left display-3 mr-4">404</h1>
            <h4 className="pt-3">{translate('404_heading')}</h4>
            <p className="text-muted float-left">
              {translate('404_main_text')}
            </p>
          </div>
          <h5>{translate('404_quote_heading')}</h5>
          <p>{robThoughts}</p>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = state => ({
  robThoughts: state.renderingRob.text
});

export default connect(mapStateToProps)(Page404Admin);
