import { useRef, useEffect } from 'react';

export const useClickOutsideElement = onClickOutside => {
  const ref = useRef(null);

  useEffect(() => {
    const handleClickOutside = event => {
      // Check if clicked element is contained inside ref
      const hasEventTarget = ref.current?.contains(event.target);
      // Check if clicked element wants to ignore click outside
      const hasClassName = event.target.className?.includes?.(
        'ignore-click-outside'
      );

      // If clicked element is outside ref, and clicked element doesn't want to ignore click outside, and callback exists
      if (!hasEventTarget && !hasClassName && onClickOutside) {
        // Invoke the callback
        onClickOutside();
      }
    };

    document.addEventListener('click', handleClickOutside, true);

    // Remove onClick event listener
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [onClickOutside]);

  return { ref };
};
