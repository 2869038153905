import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  defaultChecked: PropTypes.bool,
  onChange: PropTypes.func,
  checked: PropTypes.bool,
  label: PropTypes.any,
  inline: PropTypes.bool,
  useTableStyle: PropTypes.bool
};

const defaultProps = {
  label: ''
};

const Checkbox = props => {
  const {
    id,
    name,
    defaultChecked,
    onChange,
    checked,
    label,
    inline,
    useTableStyle
  } = props;

  const wrapperClasses = useTableStyle
    ? `custom-control custom-checkbox ${inline ? 'custom-control-inline' : ''}`
    : 'checkbox-simple';
  const inputClasses = useTableStyle ? 'custom-control-input' : '';
  const labelClasses = useTableStyle ? 'custom-control-label' : '';

  return (
    <div className={wrapperClasses}>
      <input
        type="checkbox"
        className={inputClasses}
        id={id}
        name={name}
        defaultChecked={defaultChecked}
        onChange={onChange}
        checked={checked}
      />
      <label className={labelClasses} htmlFor={id}>
        {label && <span>{label}</span>}
      </label>
    </div>
  );
};

Checkbox.propTypes = propTypes;
Checkbox.defaultProps = defaultProps;
export default Checkbox;
