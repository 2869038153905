import jsonSettings from '../views/Dashboard/DashboardModalConfiguration';
import { flattenDashboardSettings } from '../helpers/app';

const settingsReducerDefaultState = () => {
  const settingsFlat = flattenDashboardSettings(jsonSettings);

  return {
    lang: 'en',
    dashboard: settingsFlat
  };
};

export default (state = settingsReducerDefaultState, action) => {
  switch (action.type) {
    case 'SET_LANGUAGE':
      return {
        ...state,
        lang: action.lang
      };
    case 'SET_DASHBOARD_CONFIG':
      return {
        ...state,
        dashboard: action.dashboard
      };
    default:
      return state;
  }
};
