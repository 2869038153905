import { getAuthToken } from '../App';

const reactAppEnv = process.env.REACT_APP_ENV;
const apiBaseUrl = process.env[`REACT_APP_${reactAppEnv}_API_URL`] || '';

/** Creates API request object
 * @param {string} method - Request method
 * @param {string} urlSufix - Individual API endpoint
 * @param {Object} data - Request object data
 * @param {string} contentType - Request content type
 * @return {Object} Request object
 */
const apiRequest = (
  method = 'GET',
  urlSufix = '',
  data = {},
  contentType = 'application/json'
) => {
  const authToken = reactAppEnv === 'TEST' ? '' : getAuthToken();

  return {
    method: method,
    url: apiBaseUrl + urlSufix,
    headers: {
      'Content-Type': contentType,
      Authorization: authToken ? `Token ${authToken}` : ''
    },
    data: data
  };
};

export default apiRequest;
