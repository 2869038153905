import React from 'react';
import 'spinkit/css/spinkit.css';

const ModalLoader = () => {
  return (
    <div className="custom-modal-loader">
      <div className="sk-rotating-plane" />
    </div>
  );
};

export default ModalLoader;
