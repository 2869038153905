import React from 'react';
import { AppBreadcrumb } from '../../components/coreui';

const DefaultBreadcrumb = ({ appRoutes, translate }) => {
  appRoutes.forEach(route => {
    route.name = translate(route.transKey);
  });

  return <AppBreadcrumb appRoutes={appRoutes} />;
};

export default DefaultBreadcrumb;
