import React from 'react';
import PropTypes from 'prop-types';
import {
  sidebarCssClasses,
  toggleBodyClasses,
  applyBodyClasses
} from './shared/classes';

const propTypes = {
  className: PropTypes.string,
  display: PropTypes.any,
  mobile: PropTypes.bool
};

const defaultProps = {
  className: '',
  display: 'lg',
  mobile: false
};

const AppSidebarToggler = props => {
  const { className, display, mobile } = props;

  return (
    <button
      className={`navbar-toggler ${className}`}
      onClick={() => {
        // Hack to clear mobile classes if they are left over
        if (!mobile) {
          applyBodyClasses({}, { display: sidebarCssClasses[''] });
        }

        toggleBodyClasses(props, {
          display: mobile ? sidebarCssClasses[''] : sidebarCssClasses[display]
        });
      }}
    >
      <span
        data-ignore-click-outside={true}
        className={'navbar-toggler-icon ignore-click-outside'}
      ></span>
    </button>
  );
};

AppSidebarToggler.propTypes = propTypes;
AppSidebarToggler.defaultProps = defaultProps;

export default AppSidebarToggler;
