import React from 'react';
import PropTypes from 'prop-types';
import loader from '../../assets/img/loader.gif';

const propTypes = {
  loaderOffset: PropTypes.number
};

const defaultProps = {
  loaderOffset: 435
};

const Loader = props => {
  const { loaderOffset } = props;
  const style = { height: `calc(100vh - ${loaderOffset}px)` };

  return (
    <div className="app-loader" style={style}>
      <img src={loader} alt="Loading..." />
    </div>
  );
};

Loader.propTypes = propTypes;
Loader.defaultProps = defaultProps;
export default Loader;
