import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { applyBodyClasses } from './shared/classes';

const propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  fixed: PropTypes.bool
};

const defaultProps = {
  className: '',
  fixed: false
};

const AppHeader = props => {
  const { className, children } = props;

  useEffect(() => {
    applyBodyClasses(props, { fixed: 'header-fixed' });
  }, [props]);

  return (
    <header className={`app-header navbar ${className}`}>{children}</header>
  );
};

AppHeader.propTypes = propTypes;
AppHeader.defaultProps = defaultProps;

export default AppHeader;
