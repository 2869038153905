import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Form,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  FormGroup,
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'reactstrap';
import ModalLoader from '../../components/ModalLoader';
import {
  startSetUserSettings,
  startEditUserSettings
} from '../../actions/profile';
import { deserializeData, serializeData } from './userSettingsSerializer';
import ContinentCountrySelect from '../../components/ContinentCountrySelect';
import Checkbox from '../../components/Checkbox/Checkbox';

class UserModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: 'ORIGINATION',
      id: null,
      description: '',
      domain: '',
      allowed_origination_countries: {},
      allowed_destination_countries: {},
      isLoading: true,
      message: ''
    };

    this.onTabChange = this.onTabChange.bind(this);
    this.onNonGeoChange = this.onNonGeoChange.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    this.props.startSetUserSettings().then(() => {
      const { userSettings } = this.props;
      const settings = deserializeData(_.cloneDeep(userSettings));

      this.setState({ ...settings });
    });
  }

  onTabChange(tab) {
    const { activeTab } = this.state;

    if (tab === activeTab) return;

    this.setState({ activeTab: tab });
  }

  onNonGeoChange() {
    const { allowed_destination_countries } = this.state;

    if (allowed_destination_countries.includes('001')) {
      const index = allowed_destination_countries.indexOf('001');
      allowed_destination_countries.splice(index, 1);
    } else {
      allowed_destination_countries.push('001');
    }

    this.setState({ allowed_destination_countries });
  }

  onChange(selected) {
    const { activeTab } = this.state;

    if (activeTab === 'ORIGINATION') {
      this.setState({ allowed_origination_countries: selected });
    }

    if (activeTab === 'DESTINATION') {
      this.setState({ allowed_destination_countries: selected });
    }
  }

  onSubmit(e) {
    e.preventDefault();

    const { activeTab, isLoading, message, ...rest } = this.state;
    const { startEditUserSettings, toggleModal, translate } = this.props;

    const updates = serializeData(rest);
    const origination = updates.allowed_origination_countries;
    const destination = updates.allowed_destination_countries;

    if (origination.length === 0) {
      this.setState({
        message: translate('user_management_error_origin_country')
      });
      return;
    }

    if (destination.length === 0) {
      this.setState({
        message: translate('user_management_error_destination_country')
      });
      return;
    }

    this.setState({ message: translate('general_saving') });

    startEditUserSettings(updates)
      .then(() => toggleModal())
      .catch(() => {
        this.setState({ message: translate('apiError_request') });
      });
  }

  render() {
    const { translate } = this.props;
    const {
      activeTab,
      allowed_origination_countries,
      allowed_destination_countries
    } = this.state;

    return (
      <Form onSubmit={this.onSubmit}>
        <ModalHeader toggle={this.props.toggleModal}>
          {this.state.description
            ? `${this.state.description} - ${translate(
                'user_management_user_settings'
              )}`
            : translate('user_management_user_settings')}
        </ModalHeader>
        {this.state.isLoading ? (
          <ModalLoader />
        ) : (
          <div>
            <ModalBody>
              <Card>
                {this.state.message &&
                  (this.state.message === translate('general_saving') ? (
                    <CardHeader className="text-info">
                      <i className="far fa-info-circle" /> {this.state.message}
                    </CardHeader>
                  ) : (
                    <CardHeader className="text-danger">
                      <i className="far fa-exclamation-triangle" />{' '}
                      {this.state.message}
                    </CardHeader>
                  ))}
                <CardBody>
                  <Nav tabs>
                    <NavItem>
                      <NavLink
                        className={activeTab === 'ORIGINATION' ? 'active' : ''}
                        onClick={() => {
                          this.onTabChange('ORIGINATION');
                        }}
                      >
                        {translate('user_management_origin_countries')}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={activeTab === 'DESTINATION' ? 'active' : ''}
                        onClick={() => {
                          this.onTabChange('DESTINATION');
                        }}
                      >
                        {translate('user_management_destination_country')}
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent>
                    <TabPane>
                      {activeTab === 'ORIGINATION' && (
                        <FormGroup>
                          <span className="modal-help-text mb-2">
                            {translate(
                              'user_management_origin_countries-warning'
                            )}
                          </span>
                          <ContinentCountrySelect
                            selected={allowed_origination_countries}
                            onChange={this.onChange}
                          />
                        </FormGroup>
                      )}
                      {activeTab === 'DESTINATION' && (
                        <FormGroup>
                          <span className="modal-help-text mb-2">
                            {translate(
                              'user_management_destination_countries-warning'
                            )}
                          </span>
                          <ContinentCountrySelect
                            selected={allowed_destination_countries}
                            onChange={this.onChange}
                          />
                          <FormGroup check className="checkbox">
                            <Checkbox
                              id="user_modal_001"
                              name="001"
                              defaultChecked={allowed_destination_countries.includes(
                                '001'
                              )}
                              onChange={this.onNonGeoChange}
                              label={translate(
                                'user_management_non_geo_countries'
                              )}
                            />
                          </FormGroup>
                          <span className="modal-help-text">
                            {translate(
                              'user_management_non_geo_numbers_tooltip'
                            )}
                          </span>
                        </FormGroup>
                      )}
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </ModalBody>
            <ModalFooter>
              <Button type="submit" color="orange">
                <i className="far fa-check" /> {translate('general_save')}
              </Button>
              <Button color="secondary" onClick={this.props.toggleModal}>
                <i className="fal fa-times-circle" />{' '}
                {translate('general_cancel')}
              </Button>
            </ModalFooter>
          </div>
        )}
      </Form>
    );
  }
}

const mapStateToProps = state => {
  return {
    userSettings: state.profile.userSettings
  };
};

const mapDispatchToProps = dispatch => ({
  startSetUserSettings: () => dispatch(startSetUserSettings()),
  startEditUserSettings: updates => dispatch(startEditUserSettings(updates))
});

export default connect(mapStateToProps, mapDispatchToProps)(UserModal);
