import React, { useEffect } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { sidebarCssClasses, applyBodyClasses } from './shared/classes';
import { useClickOutsideElement } from '../../hooks/clickOutsideElement';

const onClickOut = () => {
  if (document.body.classList.contains('sidebar-show')) {
    document.body.classList.remove('sidebar-show');
  }
};

const propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  display: PropTypes.string,
  fixed: PropTypes.bool
};

const defaultProps = {
  display: '',
  fixed: false
};

const AppSidebar = props => {
  const { children, className } = props;
  const { ref } = useClickOutsideElement(onClickOut);

  useEffect(() => {
    applyBodyClasses(props, {
      fixed: 'sidebar-fixed',
      display: sidebarCssClasses[props.display]
    });
  }, [props]);

  var classes = classNames(className, 'sidebar');

  return (
    <div ref={ref} className={classes}>
      {children}
    </div>
  );
};

AppSidebar.propTypes = propTypes;
AppSidebar.defaultProps = defaultProps;

export default AppSidebar;
