export const deserializeData = userSettings => {
  return {
    ...userSettings,
    isLoading: false,
    message: ''
  };
};

export const serializeData = userSettings => {
  return { ...userSettings };
};
