import React from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';

const propTypes = {
  className: PropTypes.string,
  navConfig: PropTypes.any
};

const defaultProps = {
  className: '',
  navConfig: {
    items: [
      {
        name: 'Dashboard',
        url: '/dashboard',
        icon: 'icon-speedometer',
        badge: { variant: 'info', text: 'NEW' }
      }
    ]
  },
  isOpen: false
};

const AppSidebarNav = props => {
  const { className, navConfig } = props;

  return (
    <PerfectScrollbar className={`sidebar-nav ${className}`}>
      <nav className={'nav'}>
        {navConfig.items.map(item => {
          return (
            <NavLink className={'nav-link'} to={item.url} key={item.name}>
              <i className={`nav-icon ${item.icon}`} />
              {item.name}
            </NavLink>
          );
        })}
      </nav>
    </PerfectScrollbar>
  );
};

AppSidebarNav.propTypes = propTypes;
AppSidebarNav.defaultProps = defaultProps;

export default AppSidebarNav;
