/** Checks if Array is empty
 * @param {Array} item - An array to check
 * @return {boolean} true/false
 */
export const isEmpty = item => !(Array.isArray(item) && item.length);

/** Check if an object is empty and convert it to array
 * @param {Object} object - Object to check and convert
 * @return {Array.<Object>} Array containing an object
 */
export const convertObjectToArray = object => {
  if (object && typeof object === 'object' && Object.keys(object).length)
    return [{ ...object }];

  return [];
};

/** Check if item has an id and return it as a string or integer
 * @param {Array.<Object>} item - Array containing an object
 * @param {boolean} isString - Whether the returned id should be string or number
 * @return {(string|number)} Item id as a string or number
 */
export const getItemID = (item = [], isString = true) => {
  if (!isEmpty(item)) {
    const itemID = item[0]['id'] || '';

    if (itemID) {
      return isString ? `${itemID}` : parseInt(itemID, 10);
    }
  }

  return isString ? '' : null;
};

/** Check if item has a label and return it as a string
 * @param {Array.<Object>} item - Array containing an object
 * @return {string} Item label
 */
export const getItemLabel = item => {
  if (!isEmpty(item)) {
    if (item[0]['label']) return item[0]['label'];
  }

  return '';
};
