import axios from 'axios';

// GENERIC_SET_ITEMS
export const setItems = (
  items = [],
  query = '',
  currentPage = 1,
  totalItems = 0,
  sortField = '',
  sortOrder = ''
) => ({
  type: 'GENERIC_SET_ITEMS',
  items,
  query,
  currentPage,
  totalItems,
  sortField,
  sortOrder
});

// GENERIC_UPDATE_ITEM
export const updateItem = (id, updates) => {
  return {
    type: 'GENERIC_UPDATE_ITEM',
    id: id,
    updates: updates
  };
};

// GENERIC_SET_ITEM_DATA
export const setItemData = (itemID = null, nextPage = null) => ({
  type: 'GENERIC_SET_ITEM_DATA',
  itemID,
  nextPage
});

// GENERIC_SET_ADDITIONAL_DATA
export const setAdditionalData = (additionalData = {}) => ({
  type: 'GENERIC_SET_ADDITIONAL_DATA',
  additionalData
});

// GENERIC_UPDATE_ADDITIONAL_DATA
export const updateAdditionalData = additionalData => ({
  type: 'GENERIC_UPDATE_ADDITIONAL_DATA',
  additionalData
});

// GENERIC_SET_ACTIVE_TAB
export const setActiveTab = (activeTab = undefined) => ({
  type: 'GENERIC_SET_ACTIVE_TAB',
  activeTab
});

// GENERIC_TOGGLE_MODAL
export const toggleModal = (modalType = '') => {
  if (typeof modalType !== 'string') modalType = '';

  return {
    type: 'GENERIC_TOGGLE_MODAL',
    modalType
  };
};

// GENERIC_MODAL_MESSAGE
export const setModalMessage = (modalMessage = '') => ({
  type: 'GENERIC_MODAL_MESSAGE',
  modalMessage
});

// GENERIC_MODAL_PROMPT
export const setModalPrompt = (modalPrompt = '') => ({
  type: 'GENERIC_MODAL_PROMPT',
  modalPrompt
});

// GENERIC_API_ERROR
export const setApiError = () => ({
  type: 'GENERIC_API_ERROR'
});

// GENERIC_SET_LOADING
export const setLoadingState = (listView = '') => ({
  type: 'GENERIC_SET_LOADING',
  listView: listView
});

// GENERIC_RESET_ITEMS
export const resetItems = () => ({
  type: 'GENERIC_RESET_ITEMS'
});

// GENERIC_API_ERROR_HANDLER
export const apiErrorHandler = (error, setError = setApiError) => {
  return dispatch => {
    if (axios.isCancel(error)) {
      console.warn('Request canceled:', error.message);
    } else {
      console.error('Error:', error);

      dispatch(setError());
    }
  };
};
