import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import { appData } from '../App';

export const PublicRoute = ({
  isAuthenticated,
  component: Component,
  ...rest
}) => {
  // Get user url path before login
  const path = appData.initialPath;
  const redirectPath = path && path !== '/' ? path : '/dashboard';

  return (
    <Route
      {...rest}
      component={props =>
        isAuthenticated ? (
          <Redirect to={redirectPath} />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};

const mapStateToProps = state => ({
  isAuthenticated: !!state.auth.uid
});

export default connect(mapStateToProps)(PublicRoute);
