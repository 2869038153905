import React, { useContext } from 'react';
import Tour from 'reactour';
import { connect } from 'react-redux';
import { AppContext } from '../../AppContext';
import { setTourCurrentStep, closeTour } from '../../actions/app_tour';

const AppTour = props => {
  const { translate } = useContext(AppContext);
  const { steps, isTourOpen, setCurrentStep, closeTour } = props;

  steps.forEach(step => {
    step.content = translate(step.transKey);
  });

  return (
    <Tour
      steps={steps}
      isOpen={isTourOpen}
      getCurrentStep={step => setCurrentStep(step)}
      onRequestClose={closeTour}
    />
  );
};

const mapStateToProps = state => ({
  steps: state.appTour.steps,
  isTourOpen: state.appTour.openTour
});

const mapDispatchToProps = dispatch => ({
  setCurrentStep: step => dispatch(setTourCurrentStep(step)),
  closeTour: () => dispatch(closeTour())
});

export default connect(mapStateToProps, mapDispatchToProps)(AppTour);
