export const sidebarCssClasses = {
  '': 'sidebar-show',
  sm: 'sidebar-sm-show',
  md: 'sidebar-md-show',
  lg: 'sidebar-lg-show',
  xl: 'sidebar-xl-show'
};

export const asideMenuCssClasses = {
  '': 'aside-menu-show',
  sm: 'aside-menu-sm-show',
  md: 'aside-menu-md-show',
  lg: 'aside-menu-lg-show',
  xl: 'aside-menu-xl-show'
};

export const applyBodyClasses = (props, classMap) => {
  for (const key in classMap) {
    const containedClass = classMap[key];

    if (!!props[key]) {
      if (!document.body.classList.contains(containedClass)) {
        document.body.classList.add(containedClass);
      }
    } else if (document.body.classList.contains(containedClass)) {
      document.body.classList.remove(containedClass);
    }
  }
};

export const toggleBodyClasses = (props, classMap) => {
  for (const key in classMap) {
    const containedClass = classMap[key];

    if (!!props[key]) {
      if (!document.body.classList.contains(containedClass)) {
        document.body.classList.add(containedClass);
      } else {
        document.body.classList.remove(containedClass);
      }
    }
  }
};
