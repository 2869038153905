import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setLanguage } from './actions/settings';

// Load translations
const localeEn = require(`./locale/en/messages.json`);
const localeNl = require(`./locale/nl_BE/messages.json`);
const localeFr = require(`./locale/fr/messages.json`);

const translations = { en: localeEn, nl: localeNl, fr: localeFr };

const AppContext = React.createContext();

class AppProvider extends Component {
  state = {
    translations: translations,
    activeLanguage: 'en'
  };

  constructor(props) {
    super(props);

    this.state.activeLanguage = props.getActiveLanguage();
  }

  translate = transKey => {
    const { translations, activeLanguage } = this.state;

    if (translations.hasOwnProperty(activeLanguage)) {
      const languageData = translations[activeLanguage];
      if (languageData.hasOwnProperty(transKey)) {
        return languageData[transKey];
      } else {
        return `Missing translation key ${transKey} for language ${activeLanguage}`;
      }
    } else {
      return `Missing language: ${activeLanguage}`;
    }
  };

  setActiveLanguage = activeLanguage => {
    this.props.setActiveLanguage(activeLanguage);
    this.setState({ activeLanguage });
  };

  render() {
    const { children } = this.props;
    const { translate, setActiveLanguage } = this;
    const { activeLanguage } = this.state;

    return (
      <AppContext.Provider
        value={{
          translate,
          activeLanguage,
          setActiveLanguage
        }}
      >
        {children}
      </AppContext.Provider>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  setActiveLanguage: lang => {
    dispatch(setLanguage(lang));
  }
});

const mappedProvider = connect(null, mapDispatchToProps)(AppProvider);
export { mappedProvider as AppProvider };
export { AppContext };
