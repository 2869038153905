import axios from 'axios';
import apiRequest from '../helpers/api';

// SET_RENDERING_ROB
export const setRenderingRob = text => ({
  type: 'SET_RENDERING_ROB',
  text
});

export const startSetRenderingRob = () => {
  return dispatch => {
    const url = 'rendering_rob/random_404/';

    const config = apiRequest('GET', url);

    return axios(config)
      .then(data => {
        const robThoughts = data.data.text;
        //console.log('rob', robThoughts);
        dispatch(setRenderingRob(robThoughts));
      })
      .catch(() => {
        dispatch(setRenderingRob('Voila!'));
      });
  };
};
