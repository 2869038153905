export default {
  items: [
    {
      name: 'Dashboard',
      authKey: 'dashboard',
      transKey: 'menu_dashboard',
      url: '/dashboard',
      icon: 'fal fa-tachometer-alt'
    },
    {
      name: 'Clients',
      authKey: 'clients',
      transKey: 'menu_clients',
      url: '/clients',
      icon: 'fal fa-users',
      subMenu: [
        {
          name: 'Overview',
          authKey: 'clients',
          transKey: 'menu_clients_overview',
          url: '/clients/overview',
          icon: 'far fa-user-chart'
        },
        {
          name: 'Reseller Invoices',
          authKey: 'reseller_invoices',
          transKey: 'menu_invoices',
          url: '/clients/reseller-invoices',
          icon: 'far fa-file-invoice-dollar'
        }
      ]
    },
    /*{
      name: 'Group Overview',
      authKey: 'group_overview',
      transKey: 'menu_group_overview',
      url: '/group-overview',
      icon: 'fal fa-user-chart'
    },*/
    {
      name: 'Phonebooks',
      authKey: 'phonebooks',
      transKey: 'menu_phonebooks',
      url: '/phonebooks',
      icon: 'fal fa-address-book'
    },
    {
      name: 'Call Flow',
      authKey: 'call_flow',
      transKey: 'menu_configuration_callflow',
      url: '/call-flow',
      icon: 'fal fa-project-diagram'
    },
    {
      name: 'Extensions',
      authKey: 'configuration_extension',
      transKey: 'menu_configuration_extensions',
      url: '/extension',
      icon: 'fal fa-user-friends'
    },
    {
      name: 'Reports',
      authKey: 'reports',
      transKey: 'menu_reports',
      url: '/reports',
      icon: 'fal fa-file-prescription',
      subMenu: [
        {
          name: 'CDR Viewer',
          authKey: 'cdr_viewer',
          transKey: 'menu_cdr_viewer',
          url: '/reports/cdr-viewer',
          icon: 'fal fa-list-ul'
        },
        {
          name: 'Invoices',
          authKey: 'invoices',
          transKey: 'menu_invoices',
          url: '/reports/invoices',
          icon: 'fal fa-file-invoice-dollar'
        }
      ]
    },
    {
      name: 'Configuration',
      authKey: 'configuration',
      transKey: 'menu_configuration',
      url: '/configuration',
      icon: 'fal fa-cog',
      subMenu: [
        {
          name: 'Devices',
          authKey: 'configuration_devices',
          transKey: 'menu_configuration_devices',
          url: '/configuration/devices',
          icon: 'far fa-phone-office'
        },
        {
          name: 'Media Library',
          authKey: 'configuration_media_library',
          transKey: 'menu_configuration_media_library',
          url: '/configuration/media-library',
          icon: 'far fa-folder-open'
        },
        {
          name: 'Phone numbers',
          authKey: 'configuration_did_routing',
          transKey: 'menu_configuration_did_routing',
          url: '/configuration/did-routing',
          icon: 'far fa-phone'
        },
        {
          name: 'Fifo Queue',
          authKey: 'configuration_fifo_queue',
          transKey: 'menu_configuration_fifoqueue',
          url: '/configuration/fifo-queue',
          icon: 'far fa-list-ol'
        },
        {
          name: 'Ring Groups',
          authKey: 'configuration_ring_group',
          transKey: 'menu_configuration_ring_group',
          url: '/configuration/ring-group',
          icon: 'far fa-bell'
        },
        {
          name: 'Roles',
          authKey: 'configuration_group',
          transKey: 'menu_configuration_group',
          url: '/configuration/group',
          icon: 'far fa-users-class'
        },
        {
          name: 'Sites',
          authKey: 'configuration_sites',
          transKey: 'menu_configuration_sites',
          url: '/configuration/sites',
          icon: 'far fa-location'
        },
        {
          name: 'Time Schedule',
          authKey: 'time_schedule',
          transKey: 'menu_time_schedule',
          url: '/configuration/time-schedule',
          icon: 'far fa-sign'
        },
        {
          name: 'Voicemail',
          authKey: 'voicemail',
          transKey: 'menu_voicemail',
          url: '/configuration/voicemail',
          icon: 'far fa-voicemail'
        },
        {
          name: 'Wakeup Call',
          authKey: 'configuration_wakeup_call',
          transKey: 'menu_configuration_wakeup_call',
          url: '/configuration/wakeup-call',
          icon: 'far fa-alarm-clock'
        }
      ]
    },
    {
      name: 'Phone',
      authKey: 'phone',
      transKey: 'menu_phone',
      url: '/phone',
      icon: 'fal fa-alarm-clock'
    }
  ]
};
