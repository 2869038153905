import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

// App reducers
import authReducer from '../reducers/auth';
import profileReducer from '../reducers/profile';
import clientsReducer from '../reducers/clients';
import genericItemsReducer from '../reducers/generic_items';
import groupOverviewReducer from '../reducers/group_overview';
import helpSupportReducer from '../reducers/help_support';
import callFlowGUIReducer from '../reducers/callflow_gui';
import renderingRobReducer from '../reducers/rendering_rob';
import eventsReducer from '../reducers/events';
import settingsReducer from '../reducers/settings';
import cdrViewerReducer from '../reducers/cdr_viewer';
import appTourReducer from '../reducers/app_tour';
import pricingReducer from '../reducers/pricing';

// Set redux dev tools
const isProduction = process.env.NODE_ENV === 'production';

const composeEnhancers = isProduction
  ? compose
  : window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// Configure redux store
const config = {
  key: 'root',
  storage,
  whitelist: ['auth', 'profile', 'settings']
};

const appReducer = combineReducers({
  auth: authReducer,
  profile: profileReducer,
  clients: clientsReducer,
  genericItems: genericItemsReducer,
  groupOverview: groupOverviewReducer,
  helpSupport: helpSupportReducer,
  callFlowGUI: callFlowGUIReducer,
  renderingRob: renderingRobReducer,
  events: eventsReducer,
  settings: settingsReducer,
  cdrViewer: cdrViewerReducer,
  appTour: appTourReducer,
  pricing: pricingReducer
});

const reduxProfiler = () => next => action => {
  if (performance.mark === undefined) return next(action);
  performance.mark(`redux_${action.type}_start`);
  const result = next(action);
  performance.mark(`redux_${action.type}_end`);
  performance.measure(
    `redux_${action.type}`,
    `redux_${action.type}_start`,
    `redux_${action.type}_end`
  );
  return result;
};

export default () => {
  let store = createStore(
    persistReducer(config, appReducer),
    composeEnhancers(
      isProduction
        ? applyMiddleware(thunk)
        : applyMiddleware(thunk, reduxProfiler)
    )
  );
  let persistor = persistStore(store);

  return { persistor, store };
};

/*
 *Sets redux store to undefined
 *
 * Not in use right now, to use it modify createStore object
 * replace appReducer with rootReducer in persistReducer(config, appReducer),
 */

/*
const rootReducer = (state, action) => {
  if (action.type === 'USER_LOGOUT') {
    state.auth = undefined;
  }

  return appReducer(state, action);
};
*/
