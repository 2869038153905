// Customers Reducer

const helpSupportReducerDefaultState = {
  helpSupport: [],
  isLoading: true,
  noErrors: true
};

export default (state = helpSupportReducerDefaultState, action) => {
  switch (action.type) {
    case 'SET_HELP_SUPPORT':
      return {
        ...state,
        helpSupport: action.helpSupport,
        isLoading: false
      };
    case 'SET_HELP_SUPPORT_ERROR':
      return {
        ...state,
        isLoading: false,
        noErrors: false
      };
    case 'RESET_HELP_SUPPORT':
      return {
        ...state,
        isLoading: true,
        noErrors: true
      };
    default:
      return state;
  }
};
