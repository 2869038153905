// Pricing Reducer

const pricingDefaultState = {
  pricing: [],
  noErrors: true
};

export default (state = pricingDefaultState, action) => {
  switch (action.type) {
    case 'SET_PRICING':
      return {
        ...state,
        pricing: action.pricing
      };
    case 'SET_PRICING_ERROR':
      return {
        ...state,
        noErrors: false
      };
    case 'RESET_PRICING':
      return {
        noErrors: true,
        pricing: []
      };
    default:
      return state;
  }
};
