import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const propTypes = {
  color: PropTypes.string,
  label: PropTypes.bool,
  outline: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
    PropTypes.oneOf(['', 'alt'])
  ]),
  size: PropTypes.oneOf(['', 'lg', 'sm']),
  checked: PropTypes.bool,
  defaultChecked: PropTypes.bool,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func,
  variant: PropTypes.oneOf(['', '3d', 'pill']),
  className: PropTypes.string,
  dataOn: PropTypes.string,
  dataOff: PropTypes.string
};

const defaultProps = {
  color: 'secondary',
  label: false,
  outline: false,
  size: '',
  checked: undefined,
  defaultChecked: undefined,
  disabled: undefined,
  required: undefined,
  variant: '',
  dataOn: 'On',
  dataOff: 'Off'
};

const AppSwitch = props => {
  const [checked, setChecked] = useState(props.checked);
  const {
    className,
    color,
    label,
    outline,
    size,
    defaultChecked,
    value,
    disabled,
    required,
    name,
    onChange,
    variant,
    dataOn,
    dataOff
  } = props;

  useEffect(() => {
    setChecked(props.checked);
  }, [props.checked]);

  const classes = classNames(
    className,
    'switch',
    label ? 'switch-label' : false,
    size ? 'switch-' + size : false,
    variant ? 'switch-' + variant : false,
    'switch' +
      (outline ? '-outline' : '') +
      '-' +
      color +
      (outline === 'alt' ? '-alt' : ''),
    'form-check-label'
  );
  const inputClasses = classNames('switch-input', 'form-check-input');
  const sliderClasses = classNames('switch-slider');

  return (
    <label className={classes}>
      <input
        name={name}
        className={inputClasses}
        type={'checkbox'}
        checked={checked}
        defaultChecked={defaultChecked}
        onChange={e => {
          if (defaultChecked) {
            setChecked(!checked);
          }
          onChange(e);
        }}
        required={required}
        disabled={disabled}
        value={value}
      />
      <span
        className={sliderClasses}
        htmlFor={name}
        data-checked={dataOn}
        data-unchecked={dataOff}
      />
    </label>
  );
};

AppSwitch.propTypes = propTypes;
AppSwitch.defaultProps = defaultProps;

export default AppSwitch;
