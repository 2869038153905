import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const propTypes = {
  className: PropTypes.string,
  full: PropTypes.any,
  minimized: PropTypes.any
};

const defaultProps = {
  className: ''
};

const AppNavbarBrand = props => {
  const { className, full, minimized } = props;

  return (
    <Link className={`navbar-brand ${className}`} to={'/'}>
      {full && (
        <img
          className={'navbar-brand-full'}
          src={full.src}
          height={full.height ? full.height : 'auto'}
          width={full.width ? full.width : 'auto'}
          alt={full.alt ? full.alt : ''}
        />
      )}
      {minimized && (
        <img
          className={'navbar-brand-minimized'}
          src={minimized.src}
          height={minimized.height ? minimized.height : 'auto'}
          width={minimized.width ? minimized.width : 'auto'}
          alt={minimized.alt ? minimized.alt : ''}
        />
      )}
    </Link>
  );
};

AppNavbarBrand.propTypes = propTypes;
AppNavbarBrand.defaultProps = defaultProps;

export default AppNavbarBrand;
