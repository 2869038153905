// Tour Reducer

const sitesReducerDefaultState = {
  steps: [],
  currentStep: 0,
  openTour: false
};

export default (state = sitesReducerDefaultState, action) => {
  switch (action.type) {
    case 'SET_TOUR_STEPS':
      return {
        ...state,
        steps: action.steps,
        openTour: true
      };
    case 'OPEN_TOUR':
      return {
        ...state,
        openTour: true
      };
    case 'SET_TOUR_CURRENT_STEP':
      return {
        ...state,
        currentStep: action.step
      };
    case 'CLOSE_TOUR':
      return {
        ...state,
        openTour: false
      };
    default:
      return state;
  }
};
