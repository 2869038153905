import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav
} from 'reactstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { history } from '../../routers/AppRouter';
import { setInitialPath } from '../../App';

import {
  /*AppAsideToggler,*/
  AppNavbarBrand,
  AppSidebarToggler
} from '../../components/coreui';
import logo from '../../assets/img/brand/logo.svg';
import sygnet from '../../assets/img/brand/sygnet.svg';

import ModalWrapper from '../../components/Modal';
import ChangePasswordModal from './ChangePasswordModal';
import UserModal from './UserModal';
import ImpersonateModal from './ImpersonateModal';
import { startLogout } from '../../actions/auth';
import { startSetProfile, disableImpersonate } from '../../actions/profile';
import { destroyFaqBOT } from '../../FaqBOT';

const propTypes = {
  children: PropTypes.node
};

const defaultProps = {};

class DefaultHeader extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modalType: 'PASSWORD_MODAL',
      dropdownType: '',
      isOpen: false
    };

    this.toggle = this.toggle.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.disableImpersonateMode = this.disableImpersonateMode.bind(this);
    this.logout = this.logout.bind(this);
  }

  componentDidMount() {
    if (this.props.initialPassword)
      this.setState({ isOpen: !this.state.isOpen });
  }

  toggle(event, type) {
    if (!event.currentTarget.id && event.target.name !== 'external_link') {
      event.preventDefault();
    }

    const { dropdownType } = this.state;
    const currentType = dropdownType !== type ? type : '';

    this.setState({ dropdownType: currentType });
  }

  toggleModal() {
    if (!this.props.initialPassword)
      this.setState({ isOpen: !this.state.isOpen });
  }

  disableImpersonateMode() {
    this.props.disableImpersonate();
    this.props.startSetProfile().then(() => history.push('/dashboard'));
  }

  logout() {
    // Reset initial path to prevent impersonate issues
    setInitialPath();
    // Begin logout process
    this.props.startLogout();
    // Destroy instance of FAQ BOT
    destroyFaqBOT();
  }

  render() {
    const {
      reseller,
      canImpersonate,
      impersonateMode,
      impersonateGroup,
      groupName,
      groupData,
      activeLanguage,
      translate
    } = this.props;
    const { modalType, dropdownType } = this.state;
    const modalClass =
      modalType === 'USER_MODAL' ? 'primary modal-md' : 'primary';

    const HeaderDropdown = props => {
      const { type, children } = props;
      const isOpen = dropdownType === type;
      const toggleDropdown = event => this.toggle(event, type);

      return (
        <Dropdown nav isOpen={isOpen} toggle={toggleDropdown}>
          {children}
        </Dropdown>
      );
    };

    const ImpersonateComponent = () => {
      const { label } = groupData;

      if (!impersonateMode) return null;

      let labelFormatted = label;
      if (labelFormatted.length > 30) {
        labelFormatted = labelFormatted.substring(0, 27) + '...';
      }

      return (
        <Button
          type="button"
          className="btn-pill btn-outline-warning mr-1 ml-1 hover-change"
          data-toggle="tooltip"
          title={translate('impersonating_sufix')}
          onClick={this.disableImpersonateMode}
        >
          <i className="far fa-user mr-1 show-default" />
          <i className="far fa-times-circle mr-1 show-hover" />
          {labelFormatted}
        </Button>
      );
    };

    return (
      <React.Fragment>
        <AppSidebarToggler className="d-lg-none" display="md" mobile />
        <AppNavbarBrand
          className={impersonateGroup ? 'hide' : ''}
          full={{ src: logo, height: 45, alt: 'TelSmart Logo' }}
          minimized={{ src: sygnet, height: 30, alt: 'TelSmart Logo' }}
        />
        <div className="navbar-divider-fancy-border" />
        <AppSidebarToggler className="d-md-down-none" display="lg" />
        <Nav className="ml-auto" style={{ marginRight: '28px' }} navbar>
          <ImpersonateComponent />
          {(canImpersonate || reseller) && (
            <span className="nav-item mr-3">
              <Link className="nav-link" to={`/group-overview`}>
                <i className="fal fa-user-chart nav-icon ml-auto mr-auto" />
                <span>{translate('menu_group_overview')}</span>
              </Link>
            </span>
          )}
          <HeaderDropdown type="HELP">
            <DropdownToggle nav>
              <i className="fal fa-question-circle nav-icon" />
              <span>{translate('help')}</span>
              <span className="ml-1 fas fa-caret-down" />
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem header tag="div" className="text-center">
                <strong>{translate('help_n_support')}</strong>
              </DropdownItem>
              <DropdownItem id="help" tag="a" href="/help_support/cheat_sheet">
                <i className="far fa-file-spreadsheet" />
                {translate('help_n_support_cheat_sheet')}
              </DropdownItem>
              <DropdownItem id="help" tag="a" href="/help_support/pricing">
                <i className="far fa-coins" />
                <span>{translate('menu_help_support_pricing')}</span>
              </DropdownItem>
              <DropdownItem>
                <a
                  id="help"
                  name="external_link"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.telsmart.eu/support/"
                >
                  <i className="far fa-external-link" />
                  {translate('help_n_support_help_center')}
                </a>
              </DropdownItem>
            </DropdownMenu>
          </HeaderDropdown>
          <HeaderDropdown type="LANGUAGE">
            <DropdownToggle nav className="text-uppercase">
              <i className="fal fa-globe nav-icon" />
              <span>{activeLanguage}</span>
              <span className="ml-1 fas fa-caret-down" />
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem header tag="div" className="text-center">
                <strong>{translate('language')}</strong>
              </DropdownItem>
              <DropdownItem onClick={() => this.props.setLanguage('en')}>
                <i className="far fa-globe" /> English
              </DropdownItem>
              <DropdownItem onClick={() => this.props.setLanguage('nl')}>
                <i className="far fa-globe" /> Nederlands
              </DropdownItem>
              <DropdownItem onClick={() => this.props.setLanguage('fr')}>
                <i className="far fa-globe" /> Français
              </DropdownItem>
            </DropdownMenu>
          </HeaderDropdown>
          <HeaderDropdown type="SETTINGS">
            <DropdownToggle nav>
              <i
                className="fal fa-user nav-icon"
                title={translate('general_profile')}
              />
              <span>{translate('general_profile')}</span>
              <span className="ml-1 fas fa-caret-down" />
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem header tag="div" className="text-center">
                <strong>{groupData.label ? groupData.label : groupName}</strong>
              </DropdownItem>
              <DropdownItem
                onClick={() => {
                  this.setState({ modalType: 'USER_MODAL' });
                  this.toggleModal();
                }}
              >
                <i className="far fa-map-marker-alt" />{' '}
                {translate('user_management_user_settings')}
              </DropdownItem>
              <DropdownItem
                onClick={() => {
                  this.setState({ modalType: 'PASSWORD_MODAL' });
                  this.toggleModal();
                }}
              >
                <i className="far fa-lock" />{' '}
                {translate('user_management_change_password')}
              </DropdownItem>
              {canImpersonate && (
                <DropdownItem
                  onClick={() => {
                    this.setState({ modalType: 'IMPERSONATE_MODAL' });
                    this.toggleModal();
                  }}
                >
                  <i className="far fa-user" /> {translate('impersonate')}
                </DropdownItem>
              )}
              <DropdownItem onClick={this.logout}>
                <i className="far fa-sign-out-alt" /> {translate('logout')}
              </DropdownItem>
            </DropdownMenu>
          </HeaderDropdown>
        </Nav>
        {/*<AppAsideToggler className="d-md-down-none" />*/}
        {/*<AppAsideToggler className="d-lg-none" mobile />*/}
        <ModalWrapper
          isOpen={this.state.isOpen}
          toggleModal={this.toggleModal}
          modalClass={modalClass}
        >
          {modalType === 'USER_MODAL' && (
            <UserModal toggleModal={this.toggleModal} translate={translate} />
          )}
          {modalType === 'PASSWORD_MODAL' && (
            <ChangePasswordModal
              initialPassword={this.props.initialPassword}
              toggleModal={this.toggleModal}
              translate={translate}
            />
          )}
          {modalType === 'IMPERSONATE_MODAL' && (
            <ImpersonateModal
              toggleModal={this.toggleModal}
              translate={translate}
            />
          )}
        </ModalWrapper>
      </React.Fragment>
    );
  }
}

DefaultHeader.propTypes = propTypes;
DefaultHeader.defaultProps = defaultProps;

const mapStateToProps = state => {
  const groupData = { id: null, label: '' };

  return {
    initialPassword: state.profile.profileData.initial_password,
    impersonateMode: state.profile.impersonate,
    canImpersonate: state.profile.profileData.can_impersonate,
    reseller: state.profile.profileData.reseller,
    impersonateGroup: state.profile.impersonateGroup,
    groupData: state.profile.profileData.group_data || groupData,
    groupName: state.profile.profileData.group_name
  };
};

const mapDispatchToProps = dispatch => ({
  startLogout: () => dispatch(startLogout()),
  startSetProfile: () => dispatch(startSetProfile()),
  disableImpersonate: () => dispatch(disableImpersonate())
});

export default connect(mapStateToProps, mapDispatchToProps)(DefaultHeader);
